<template>
    <div class="headline list-page">
        <div class="inner">
            <div class="button-group" />
            <div class="display-section">
                <el-input v-model="headlineText"></el-input>
                <button-wrapper
                    class="button"
                    type="modal"
                    color="primary"
                    @click="onUpdate"
                >
                    更新
                </button-wrapper>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GuardDisabled from '@/middleware/GuardDisabled';
import { mapMutations, mapActions } from 'vuex';

export default {
    mixins: [GuardDisabled],
    created() {
        this.init();
    },
    data() {
        return {
            headlineText: '',
        };
    },
    methods: {
        ...mapMutations({
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        async init() {
            const PAYLOAD = {
                category: 'Headline',
                function: 'get',
            };
            const RES = await this.apiClient(PAYLOAD);
            this.headlineText = RES.data.content;
        },
        async onUpdate() {
            const PAYLOAD = {
                category: 'Headline',
                function: 'update',
                successMsg: '大聲公文案已更新',
                data: {
                    content: this.headlineText,
                },
            };await this.apiClient(PAYLOAD);
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.headline {
    .display-section {
        display: flex;
    }
    .button {
        margin-left: 10px;
    }
}
</style>
